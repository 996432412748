@charset "utf-8";


$color_og1: #E86513;
$color_bl1: #2F3E3F;
$color_wh1: #F7F7F7;
$color_gray1: #E2E2E2;
$color_gray2: #B9B9B9;
$color_gray3: #797979;
$color_gray4: #D5D5D5;
$color_gr1: #E6FFEB;
$color_gr2: #C9F5D2;
$color_gr3: #C9F5D2;
$color_gr4: #E9F2EA;
$color_rd1: #CB2700;

$txt_basic: #222222;
$txt_green: #017F13;

$font_basic: "Noto Sans JP", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
$font_en: "Jost", sans-serif;