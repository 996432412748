@import "_var.scss";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*
トップページ
-------------------------------------*/
#Page.pageIndex {
  #MainImg {
    height: 800px;
    #MainImgInner {
      display: block;
      position: relative;
      .slides {
        width: 100%;
        height: 100%;
        .slide {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
        }
        .slide01 {
          background-image: url(../img/contents/top/mainimg_01.jpg);
        }
        .slide02 {
          background-image: url(../img/contents/top/mainimg_02.jpg);
        }
        .slide03 {
          background-image: url(../img/contents/top/mainimg_03.jpg);
        }
        .slick-list,
        .slick-track {
          height: 100%;
        }
      }
      h2 {
        font-size: 50px;
        text-align: justify;
        line-height: 64px;
        position: absolute;
        top: 50%;
        left: 5%;
        transform: translateY(-50%);
        span {
          font-size: 40px;
          display: block;
        }
        &:before { display: none; }
      }
      .important {
        width: 630px;
        position: absolute;
        right: 0;
        bottom: 0;
        display: flex;
        dt {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 152px;
          background-color: $txt_green;
          font-size: 14px;
          font-weight: 600;
          color: #ffffff;
        }
        dd {
          flex: 1;
          background-color: #ffffff;
          padding: 16px 0 16px 16px;
          ul li {
            & + li { margin-top: 16px; }
            a {
              padding: 0 14px 0 0;
              background-color: transparent;
              .date, .title { font-size: 14px; }
            }
          }
        }
      }
    }
  }
  #Main {
    #ContBox01 {
      padding: 68px 0 94px;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        width: 597px;
        height: 394px;
        background: url(../img/contents/img_01.png) no-repeat left top;
        background-size: 100% auto;
        position: absolute;
        top: 0;
        left: -143px;
        z-index: -1;
      }
      .innerBasic { max-width: 1290px; }
      h3 {
        margin-bottom: 45px;
      }
      .contSubBox {
        display: flex;
        flex-wrap: wrap;
        gap: 48px 30px;
        a {
          width: calc( (100% - 90px) / 4);
          text-decoration: none;
          dl {
            .photo {
              width: 100%;
              figure {
                width: 100%;
                padding-top: 66.6666666666667%;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
              }
            }
            .text {
              margin-top: 4px;
              p {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: 0;
                &.name {
                  font-size: 16px;
                  color: $txt_green;
                }
              }
            }
          }
        }
      }
    }
    #ContBox02 {
      background: url(../img/contents/bg_01.jpg) repeat left top;
      background-size: contain;
      padding: 100px 0 171px;
      .innerBasic {
        max-width: 1400px;
        padding: 0;
      }
      h3 {
        margin-bottom: 50px;
      }
      .contSubBox {
        .box {
          .content {
            h4 {
              margin-bottom: 20px;
            }
            p {
              line-height: 25px;
              margin-bottom: 10px;
              + a { margin-top: 30px; }
            }
          }
        }
        .box01 {
          .content {
            width: 100%;
            ul {
              display: flex;
              flex-wrap: wrap;
              margin-top: 48px;
              li {
                width: calc( (100% - 20px) / 3 );
                max-width: 175px;
                height: 125px;
                margin-right: 10px;
                &:nth-of-type(3n) {
                  margin-right: 0;
                }
                a {
                  display: flex;
                  align-items: flex-end;
                  width: 100%;
                  height: 100%;
                  color: #ffffff;
                  text-decoration: none;
                  position: relative;
                  overflow: hidden;
                  padding: 0 0 7px 7px;
                  overflow: hidden;
                  figure {
                    width: 100%;
                    height: 100%;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    &:after {
                      content: '';
                      width: 100%;
                      height: 100%;
                      background: linear-gradient(to bottom, rgba(255,255,255,0), #585858);
                      position: absolute;
                      top: 0;
                      left: 0;
                    }
                  }
                  p {
                    width: 100%;
                    font-size: 14px;
                    font-weight: 600;
                    background: url(../img/contents/arrow_01_03.png) no-repeat right center;
                    background-size: 10px auto;
                    margin-bottom: 0;
                    position: relative;
                  }
                }
              }
            }
          }
        }
      }
    }
    #ContBox03 {
      padding: 108px 0 122px;
      .contSubBox {
        display: flex;
        > h3 {
          width: 210px;
          text-align: left;
        }
        > ul {
          flex: 1;
        }
      }
    }
  }
}

/*
会社案内
-------------------------------------*/
#PageCompany.pageIndex {
  #MainImg {
    #MainImgInner {
      background-image: url(../img/contents/company/mainimg_pc.jpg);
    }
  }
  #Main {
    padding-top: 128px;
    padding-bottom: 268px;
    #ContBox01 {
      .boxLink {
        li {
          a {
            display: flex;
            text-decoration: none;
            padding: 18px 0 18px 48px;
            border-bottom: 1px solid $color_gray4;
            span {
              font-family: $font_en;
              color: $color_gray2;
              margin-right: 20px;
            }
          }
        }
      }
      .innerBasic {
        max-width: 100%;
        flex: 1;
      }
      .contSubBox {
        margin-bottom: 100px;
        &:last-of-type {
          margin-bottom: 0;
        }
        .subBox {
          padding: 0 65px;
          p:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      .contSubBox01 {
        .subBox p:last-of-type {
          display: flex;
          align-items: center;
          justify-content: right;
          margin: 45px 0 0;
          span {
            display: inline-block;
            width: 127px;
            height: 26px;
            background: url(../img/contents/company/txt_01.png) no-repeat left top;
            background-size: 100% auto;
            margin-left: 14px;
          }
        }
      }
      .contSubBox02 {
        .box01 {
          background-color: $color_wh1;
          padding: 30px 40px;
          margin: 26px 0 40px;
          ol {
            li {
              font-weight: 600;
              color: $txt_green;
              line-height: 25px;
              & + li { margin-top: 15px; }
            }
          }
        }
        .box02 {
          display: flex;
          align-items: center;
          margin: 19px 0 25px;
          p {
            display: flex;
            align-items: center;
            color: $txt_green;
            margin-left: 15px;
            margin-bottom: 0;
            span {
              font-family: $font_en;
              font-size: 20px;
              font-weight: 500;
              color: #FFFFFF;
              background-color: $txt_green;
              width: 35px;
              height: 35px;
              margin-right: 10px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
            }
            &:first-of-type {
              color: $txt_basic;
              margin-left: 0;
            }
          }
        }
      }
      .contSubBox03 {
        table {
          .staff {
            dl {
              display: flex;
              dt { margin-right: 1em; }
              dd { flex: 1; }
            }
          }
        }
      }
      .contSubBox05 {
        .subBox {
          display: grid;
          margin-bottom: 60px;
          &:last-of-type {
            margin-bottom: 0;
          }
          .text {
            h4 {
              font-size: 18px;
              font-weight: 600;
              line-height: 26px;
              margin-bottom: 6px;
            }
            p {
              margin-bottom: 0;
            }
          }
          .access {
            dt {
              width: 100%;
              background-color: $color_bl1;
              font-size: 18px;
              font-weight: 600;
              color: #FFFFFF;
              padding: 8px 19px;
            }
            dd {
              padding: 20px 25px;
              border: solid $color_gray1;
              border-width: 0 1px 1px 1px;
            }
          }
          .image {
            img {
              width: 100%;
              height: auto;
            }
            p {
              font-size: 14px;
              line-height: 21px;
              margin-top: 10px;
              margin-bottom: 0;
            }
          }
          .map {
            > div {
              padding-top: 36.08247422680412%;
              position: relative;
              overflow: hidden;
            }
            iframe {
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
            }
          }
        }
      }
    }
  }
}

/*
事業内容
-------------------------------------*/
#PageBusiness {
  #MainImg {
    #MainImgInner {
      background-image: url(../img/contents/business/mainimg_pc.jpg);
    }
  }
  #Main {
    padding-top: 60px;
    padding-bottom: 105px;
  }
}
#PageBusiness.pageIndex,
#PageBusiness.pageCategory {
  #Main {
    #ContBox01 {
      .innerBasic {
        max-width: 1000px;
        > .box {
          margin-bottom: 52px;
        }
      }
      .box {
        display: flex;
        .image {
          width: 50%;
          margin-right: 40px;
          img {
            width: 100%;
            height: auto;
          }
        }
        .text {
          flex: 1;
          p {
            strong {
              display: inline-block;
              font-size: 25px;
              font-weight: 600;
            }
            &:last-of-type { margin-bottom: 0; }
          }
        }
      }
      .contSubBox {
        .subBox {
          padding: 0 40px;
        }
      }
      .contSubBox01 {
        margin-bottom: 80px;
        dl {
          display: flex;
          & + dl { margin-top: 20px; }
          dt {
            background-color: $color_bl1;
            font-weight: 600;
            color: #FFFFFF;
            width: 210px;
            display: flex;
            align-items: center;
            padding: 13px 20px;
          }
          dd {
            flex: 1;
            padding: 0 15px;
            display: flex;
            align-items: center;
            ul {
              li {
                position: relative;
                line-height: 30px;
                text-align: justify;
                padding-left: 17px;
                &:before {
                  content: '';
                  width: 10px;
                  height: 10px;
                  background-color: $color_gray1;
                  border-radius: 50%;
                  position: absolute;
                  top: 10px;
                  left: 0;
                }
              }
            }
          }
        }
        .subBox > p {
          margin-top: 15px;
          padding-left: 0;
          &:before { display: none; }
        }
      }
      .contSubBox02 {
        margin-bottom: 63px;
        ul {
          display: flex;
          flex-wrap: wrap;
          li {
            width: calc( (100% - 120px) / 4 );
            margin-right: 40px;
            margin-bottom: 30px;
            &:nth-of-type(4n) {
              margin-right: 0;
            }
            figure {
              width: 100%;
              padding-top: 100%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
            }
            p {
              line-height: 24px;
              margin: 10px 0 0;
            }
          }
        }
      }
      .contSubBox03 {
        .subBox + .subBox {
          margin-top: 60px;
        }
        .box {
          margin-bottom: 29px;
          .image {
             width: 53.87931034482759%;
          }
          .imageSlide {
            li {
              width: 100%;
              display: none;
              img {
                width: 100%;
                height: auto;
              }
              &.current {
                display: block;
              }
            }
          }
          .imageThumb {
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;
            li {
              width: calc( (100% - 52px ) / 5);
              margin-right: 13px;
              padding-top: 12%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              &:nth-of-type(5n) { margin-right: 0; }
              &:hover { cursor: pointer; }
              &.current { opacity: 0.5; }
            }
          }
          .text {
            dt {
              background-color: $color_gr4;
              font-weight: 600;
              padding: 11px 20px;
            }
            dd {
              padding: 20px 22px 0;
              p { line-height: 25px; }
            }
          }
        }
        table {
          td p { margin-bottom: 0; }
        }
      }
    }
  }
}

/*
各事業建設
-------------------------------------*/
#PageBusiness.pageEntry {
  #Main {
    #ContBox01 {
      .innerBasic {
        max-width: 1000px;
      }
      .subBox {
        padding: 0 36px;
        .box {
          display: flex;
          margin-bottom: 29px;
          .image {
             width: 53.87931034482759%;
             margin-right: 40px;
          }
          .imageSlide {
            li {
              width: 100%;
              display: none;
              img {
                width: 100%;
                height: auto;
              }
              &.current {
                display: block;
              }
            }
          }
          .imageThumb {
            display: flex;
            flex-wrap: wrap;
            margin-top: 15px;
            li {
              width: calc( (100% - 52px ) / 5);
              margin-right: 13px;
              padding-top: 12%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              &:nth-of-type(5n) { margin-right: 0; }
              &:hover { cursor: pointer; }
              &.current { opacity: 0.5; }
            }
          }
          .text {
            flex: 1;
            dt {
              background-color: $color_gr4;
              font-weight: 600;
              padding: 11px 20px;
            }
            dd {
              padding: 20px 22px 0;
              p {
                line-height: 25px;
                strong {
                  display: inline-block;
                  font-size: 25px;
                  font-weight: 600;
                }
                &:last-of-type { margin-bottom: 0; }
              }
            }
          }
        }
        table {
          td p { margin-bottom: 0; }
        }
      }
    }
  }
}

/*
採用情報
-------------------------------------*/
#PageRecruit.pageIndex {
  #MainImg {
    #MainImgInner {
      background-image: url(../img/contents/recruit/mainimg_pc.jpg);
    }
  }
  #Main {
    padding-top: 128px;
    padding-bottom: 112px;
    .innerBasic {
      max-width: 1000px;
    }
    .boxLink {
      margin-bottom: 52px;
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          width: 165px;
          margin-right: 40px;
          a {
            display: block;
            width: 100%;
            font-weight: 600;
            color: $txt_green;
            text-decoration: none;
            background: $color_wh1 url(../img/contents/arrow_03_01.png) no-repeat right 24px center;
            background-size: 13px auto;
            padding: 18px 40px;
          }
        }
      }
    }
    .contBox {
      margin-top: 80px;
      h3 {
        margin-bottom: 53px;
      }
      .subBox {
        padding: 0 40px;
      }
    }
    #ContBox01 {
      margin-top: 0;
    }
  }
}

/*
お問合せ・見積依頼
-------------------------------------*/
#PageContact {
  #MainImg {
    #MainImgInner {
      background-image: url(../img/contents/contact/mainimg_pc.jpg);
    }
  }
  #Main {
    #ContBox01 {
      .innerBasic {
        max-width: 1000px;
      }
      .form {
        background-color: $color_wh1;
        padding: 56px 19.1304347826087%;
        table {
          width: 100%;
          tr {
            &:last-of-type {
              td { margin-bottom: 0; }
            }
          }
          th, td {
            display: block;
            width: 100%;
          }
          th {
            font-weight: 400;
            padding-left: 58px;
            margin-bottom: 10px;
            position: relative;
            &:before {
              content: '必須';
              background-color: $color_og1;
              font-size: 14px;
              font-weight: 600;
              color: #FFFFFF;
              padding: 0 9px 0 11px;
              border-radius: 3px;
              position: absolute;
              top: 1.5px;
              left: 0;
            }
          }
          td {
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
#PageContact.pageIndex {
  #Main {
    padding-top: 128px;
    padding-bottom: 112px;
    #ContBox01 {
      .subBox {
        padding: 0 40px;
        > p { margin-bottom: 40px; }
      }
      .form {
        input, textarea {
          display: block;
          width: 100%;
          height: 60px;
          background-color: #FFFFFF;
          padding: 0 18px;
          border: 1px solid $color_gray4;
          border-radius: 5px;
        }
        textarea {
          height: 250px;
          padding: 12px 18px;
          resize: vertical;
        }
        label {
          display: flex;
          align-items: center;
          & + label { margin-top: 6px; }
          input {
            width: 20px;
            height: 20px;
            background-color: #FFFFFF;
            padding: 0;
            border: 1px solid $color_gray4;
            border-radius: 5px;
            margin-right: 8px;
            -webkit-appearance: none;
            -moz-appearance: none;
                  appearance: none;
            position: relative;
            &:after {
              content: '';
              height: 5px;
              width: 10px;
              border-bottom: 2px solid $txt_basic;
              border-left: 2px solid $txt_basic;
              opacity: 0;
              transform: rotate(-45deg);
              position: absolute;
              top: 5px;
              left: 3.5px;
            }
            &:checked:after {
              opacity: 1;
            }
          }
        }
        table {
          tr:nth-of-type(6) {
            th {
              padding-left: 0;
              &:before { display: none; }
            }
          }
        }
        .privacy {
          width: 100%;
          height: 250px;
          background-color: #FFFFFF;
          padding: 20px 24px 20px 16px;
          border: 1px solid $color_gray4;
          overflow-y: scroll;
          margin: 20px 0;
          p {
            line-height: 25px;
            margin-bottom: 25px;
            &:last-of-type { margin-bottom: 0; }
            strong {
              font-weight: 600;
            }
          }
        }
        .agree {
          padding: 0 14px;
          .image {
            width: 40px;
            height: 40px;
            background: url(../img/contents/contact/img_01.png) no-repeat left top;
            background-size: 100% auto;
            margin-left: 40px;
          }
        }
        button {
          width: 280px;
          margin: 56px auto 0;
        }
      }
    }
  }
}

/*
お問合せ・見積依頼（送信内容確認）
-------------------------------------*/
#PageContact.pageConfirm {
  #Main {
    padding-top: 128px;
    padding-bottom: 108px;
    #ContBox01 {
      .subBox {
        padding: 0 40px;
        > p {
          width: 736px;
          margin: 0 auto 40px;
        }
      }
      h3 {
        font-size: 20px;
        color: $txt_basic;
        text-align: center;
        margin-bottom: 40px;
      }
      .form {
        table {
          th {
            background-color: $color_bl1;
            font-weight: 600;
            color: #FFFFFF;
            padding: 13px 16px;
            margin-bottom: 6px;
            &:before { display: none; }
          }
          td {
            background-color: #FFFFFF;
            padding: 13px 16px;
            margin-bottom: 24px;
          }
        }
        button {
          width: 280px;
          margin: 56px auto 0;
        }
      }
      .return {
        text-align: center;
        line-height: 24px;
        margin: 20px 0 0;
        a {
          display: inline-block;
          text-decoration: none;
          background: url(../img/contents/arrow_01_05.png) no-repeat right center;
          background-size: 8px auto;
          padding-right: 22px;
          &:hover { opacity: 0.5; }
        }
      }
      .errBox {
        h4 {
          text-align: center;
          margin-bottom: 20px;
        }
        p {
          margin-bottom: 0;
          text-align: center;
        }
      }
    }
  }
}

/*
お問合せ・見積依頼（送信完了）
-------------------------------------*/
#PageContact.pageThanks {
  #Main {
    padding-top: 128px;
    padding-bottom: 109px;
    #ContBox01 {
      h3 {
        font-size: 20px;
        color: $txt_basic;
        text-align: center;
        margin-bottom: 40px;
      }
      .subBox {
        max-width: 736px;
        margin: 0 auto;
        .box {
          max-width: 530px;
          background-color: $color_wh1;
          padding: 16px;
          margin: 40px auto 56px;
          p {
            font-weight: 600;
            line-height: 40px;
            margin-bottom: 0;
          }
        }
        a {
          width: 280px;
          margin: 0 auto;
        }
      }
    }
  }
}

/*
お知らせ
-------------------------------------*/
#PageTopics {
  #MainImg {
    #MainImgInner {
      background-image: url(../img/contents/topics/mainimg_pc.jpg);
    }
  }
  #Main {
    padding-top: 128px;
    padding-bottom: 112px;
    .cateLink {
      margin-bottom: 52px;
      ul {
        display: flex;
        justify-content: center;
        li {
          width: 165px;
          margin-right: 40px;
          &:last-of-type { margin-right: 0; }
          &.current a {
            background-image: url(../img/contents/arrow_03_02.png);
            background-color: $txt_green;
            color: #FFFFFF;
            border-collapse: $txt_green;
          }
          a {
            display: block;
            text-decoration: none;
            text-align: center;
            background: url(../img/contents/arrow_03_01.png) no-repeat right 24px center;
            background-size: 13px auto;
            padding: 17px;
            border: 1px solid $color_gray3;
            &:hover { opacity: 0.5; }
          }
        }
      }
    }
    .moreBtn {
      width: 280px;
      margin: 52px auto 0;
    }
  }
}

/*
お知らせ（カテゴリ）
-------------------------------------*/
#PageTopics.pageCategory {
  #Main {
    #ContBox01 {
      h3 {
        color: $txt_basic;
        text-align: center;
        margin-bottom: 40px;
      }
    }
  }
}

/*
お知らせ（記事）
-------------------------------------*/
#PageTopics.pageEntry {
  #Main {
    #ContBox01 {
      .innerBasic {
        max-width: 1000px;
      }
      .contSubBox {
        background-color: #F8F8F8;
        padding: 56px 40px;
        margin-bottom: 52px;
      }
      .entryHead {
        margin-bottom: 30px;
        ul {
          display: flex;
          margin-bottom: 40px;
          .date {
            font-family: $font_en;
            font-size: 14px;
            font-weight: 500;
            color: $color_gray2;
            margin-right: 30px;
          }
          .cate {
            background-color: $txt_green;
            font-size: 14px;
            color: #FFFFFF;
            line-height: 1;
            padding: 3px 12px;
            border-radius: 3px;
          }
        }
        h3 {
          font-size: 25px;
          font-weight: 600;
          text-align: justify;
        }
      }
      .pageNavi {
        ul {
          max-width: 652px;
          margin: 0 auto;
          position: relative;
          .prev, .next {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            a {
              color: $txt_green;
              text-decoration: none;
              background-repeat: no-repeat;
              background-size: 8px auto;
              &:hover { opacity: 0.5; }
            }
          }
          .prev {
            left: 0;
            a {
              padding-left: 28px;
              background-image: url(../img/contents/arrow_01_04.png);
              background-position: left center;
            }
          }
          .next {
            right: 0;
            a {
              padding-right: 28px;
              background-image: url(../img/contents/arrow_02_03.png);
              background-position: right center;
            }
          }
          .home {
            width: 280px;
            margin: 0 auto;
            a { width: 100%; }
          }
        }
      }
    }
  }
}

/*
個人情報保護方針
-------------------------------------*/
#PagePrivacy.pageIndex {
  #MainImg {
    #MainImgInner {
      background-image: url(../img/contents/company/mainimg_pc.jpg);
    }
  }
  #Main {
    padding-top: 128px;
    padding-bottom: 113px;
    #ContBox01 {
      .innerBasic {
        max-width: 1000px;
        > p {
          margin-bottom: 60px;
        }
      }
      .contSubBox {
        h3 {
          font-size: 18px;
          line-height: 30px;
          color: $txt_basic;
        }
      }
      .contSubBox01 {
        margin-bottom: 80px;
        ol li {
          line-height: 30px;
          + li { margin-top: 30px; }
        }
        .subBox {
          display: flex;
          align-items: flex-end;
          margin: 56px 0;
          .box {
            background-color: $color_wh1;
            padding: 18px 20px;
            p {
              margin-bottom: 0;
              strong {
                display: block;
                font-size: 18px;
                font-weight: 600;
              }
            }
          }
          figure {
            margin-left: 18px;
          }
        }
        .textRight {
          text-align: right;
          margin-bottom: 0;
          span {
            display: inline-block;
            text-align: justify;
          }
        }
      }
      .contSubBox02 {
        .subBox {
          + .subBox { margin-top: 30px; }
          p {
            &:last-of-type { margin-bottom: 0; }
          }
          ol {
            li {
              line-height: 30px;
              text-align: justify;
            }
          }
          .box {
            counter-increment: title;
            + .box { margin-top: 30px; }
            h5 {
              position: relative;
              text-align: justify;
              padding-left: 1.5em;
              line-height: 30px;
              &:before {
                content: counter(title) ".";
                position: absolute;
                top: 0;
                left: 0;
              }
            }
            ol {
              list-style: none;
              li + li { margin-top: 30px; }
            }
          }
          .box02 {
            ol li + li { margin-top: 0; }
          }
          .box05 {
            .listTypeKana li + li {
              margin-top: 0;
            }
          }
        }
      }
    }
  }
}

/*
404
-------------------------------------*/
#Page404.pageIndex {
  #Main {
    padding: 120px 0;
    h3 {
      text-align: center;
      margin-bottom: 30px;
    }
    p {
      text-align: center;
    }
    a {
      margin: 0 auto;
    }
  }
}


@media print, screen and (min-width: 768px) {
  #Page.pageIndex {
    #MainImg {
      #MainImgInner .important {
        dd ul li a:hover {
          .date { opacity: 0.5; }
        }
      }
    }
    #Main {
      #ContBox01 .contSubBox {
        a {
          opacity: 1;
          transition: opacity 0.5s ease;
          &:hover { opacity: 0.5; }
        }
      }
    }
  }

  #PageCompany.pageIndex {
    #Main #ContBox01 {
      display: flex;
      align-items: flex-start;
      max-width: 1440px;
      margin: 0 auto;
      position: relative;
      .boxLink {
        width: 220px;
        position: sticky;
        top: 98px;
        li {
          &:first-of-type a {
            padding-top: 0;
          }
          a:hover {
            color: $txt_green;
            border-bottom-color: $txt_green;
            span { color: $txt_green; }
          }
        }
      }
      .innerBasic {
        padding: 0 62px 0 58px;
      }
    }
  }

  #PageRecruit.pageIndex {
    #Main {
      .boxLink ul li a:hover {
        color: rgba(1,127,19,0.5);
      }
    }
  }
}

@media print, screen and (min-width: 1201px) {
  #Page.pageIndex {
    #Main {
      #ContBox02 {
        .contSubBox {
          position: relative;
          overflow: hidden;
          .box {
            width: 50%;
            display: flex;
            align-items: center;
            position: relative;
            opacity: 0;
            transition: all 1.5s;
            .background {
              width: calc(100% + 257px);
              height: calc(100% - 74px);
              background-repeat: no-repeat;
              background-size: 955px auto;
              position: absolute;
              pointer-events: none;
              &.sp_display { display: none !important; }
            }
            .content {
              min-height: 510px;
              position: relative;
              padding: 46px 0;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .btnTypeBasic:hover {
                opacity: 1;
                background-color: #01570D;
              }
            }
            &:nth-of-type(odd) {
              padding-top: 74px;
              .background {
                background-position: right top;
                bottom: 0;
                right: -257px;
              }
              .content {
                padding-left: 100px;
              }
            }
            &:nth-of-type(even) {
              padding-bottom: 74px;
              position: absolute;
              right: 0;
              z-index: 1;
              .background {
                background-position: left top;
                top: 0;
                left: -255px;
              }
              .content {
                padding-right: 100px;
                padding-left: 17%;
              }
            }
            &.scrollin {
              opacity: 1;
              transform: translate(0,0);
            }
          }
          .box01 {
            margin-bottom: 50px;
            transform: translate(-50px, 0);
            .background { background-image: url(../img/contents/top/img_01.png); }
            .content {
              p { max-width: 400px; }
              ul li a {
                figure {
                  transform: scale(1);
                  transition: 0.5s ease;
                }
                &:hover {
                  figure { transform: scale(1.2); }
                }
              }
            }
          }
          .box02 {
            top: 0;
            transform: translate(50px, 0);
            transition-delay: 1s;
            .background { background-image: url(../img/contents/top/img_02.png); }
            .content {
              p { max-width: 350px; }
            }
          }
          .box03 {
            transform: translate(-50px, 0);
            .background { background-image: url(../img/contents/top/img_03.png); }
            .content {
              p { max-width: 335px; }
            }
          }
          .box04 {
            bottom: 0;
            transform: translate(50px, 0);
            transition-delay: 1s;
            .background { background-image: url(../img/contents/top/img_04.png); }
            .content {
              p { max-width: 335px; }
            }
          }
        }
      }
    }
  }

  #PageCompany.pageIndex {
    #Main #ContBox01 {
      .contSubBox05 {
        .subBox {
          grid-template-columns: 1fr 49.89690721649485%;
          column-gap: 55px;
          margin-bottom: 60px;
          &:last-of-type {
            margin-bottom: 0;
          }
          .text {
            grid-column: 1 / 2;
            grid-row: 1 / 2;
          }
          .access {
            grid-column: 1 / 2;
            grid-row: 2 / 3;
            margin-top: 18px;
          }
          .image {
            grid-column: 2 / 3;
            grid-row: 1 / 4;
          }
          .map {
            grid-column: 1 / 3;
            grid-row: 4 / 5;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

@media print, screen and (max-width: 1200px) {
  #Page.pageIndex {
    #Main {
      #ContBox02 {
        padding: 50px 0;
        .contSubBox {
          .box {
            & + .box {
              margin-top: 70px;
            }
            .background {
              padding-top: 53.33333333333333%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
              &.pc_display { display: none !important; }
            }
            .content {
              padding: 30px 15px 0;
              h4 {
                font-size: 22px;
                line-height: 30px;
                text-align: center;
                margin-bottom: 17px;
              }
              p {
                & + a {
                  margin: 20px auto 0;
                }
              }
            }
          }
          .box01 {
            .background {
              background-image: url(../img/contents/business/img_top_sp_01.jpg);
            }
            .content {
              display: flex;
              flex-direction: column;
              p { margin-bottom: 0; }
              > a {
                order: 2;
                margin: 18px auto 0;
              }
              ul {
                li {
                  width: calc( (100% - 10px) / 2);
                  margin-right: 10px;
                  margin-bottom: 12px;
                  &:nth-of-type(3n) { margin-right: 10px; }
                }
              }
            }
          }
          .box02 {
            .background {
              background-image: url(../img/contents/business/img_top_sp_02.jpg);
            }
          }
          .box03 {
            .background {
              background-image: url(../img/contents/business/img_top_sp_03.jpg);
            }
          }
          .box04 {
            .background {
              background-image: url(../img/contents/business/img_top_sp_04.jpg);
            }
          }
        }
      }
    }
  }

  #PageCompany.pageIndex {
    #Main {
      #ContBox01 {
        .contSubBox05 {
          h3 {
            margin-bottom: 30px;
          }
          .subBox {
            grid-row-gap: 10px;
            margin-bottom: 40px;
            padding-bottom: 40px;
            border-bottom: 1px solid #707070;
            .text {
              order:1;
              p {
                line-height: 20px;
              }
            }
            .image {
              p { display: none; }
            }
            .access {
              order: 3;
              margin-top: 0;
              ul li {
                line-height: 25px;
                &:before { top: 8px; }
                & + li { margin-top: 5px; }
              }
            }
            .map {
              order: 2;
              > div {
                padding-top: 69.44444444444444%;
              }
            }
          }
        }
      }
    }
  }
}

@media print, screen and (max-width: 767px) {
  /*
  トップページ
  -------------------------------------*/
  #Page.pageIndex {
    #MainImg {
      height: calc( 100vh - 70px);
      #MainImgInner {
        h2 {
          font-size: 40px;
          line-height: 53px;
          letter-spacing: 0;
          text-align: left;
          top: auto;
          bottom: 180px;
          transform: translateY(0);
          span {
            font-size: 35px;
          }
        }
        .slides {
          .slide01 {
            background-image: url(../img/contents/top/mainimg_sp_01.jpg);
          }
          .slide02 {
            background-image: url(../img/contents/top/mainimg_sp_02.jpg);
          }
          .slide03 {
            background-image: url(../img/contents/top/mainimg_sp_03.jpg);
          }
        }
        .important {
          width: 100%;
          flex-direction: column;
          dt {
            padding: 6px;
          }
          dd {
            padding: 14px 0 14px 15px;
            ul li {
              & + li { margin-top: 14px; }
              &:nth-of-type(3) {
                display: none;
              }
              a {
                .title {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }
    }
    #Main {
      #ContBox01 {
        padding: 35px 0 70px;
        &:before {
          width: 393px;
          height: 259px;
          left: -242px;
        }
        .contSubBox {
          display: block;
          padding: 0 22px;
          margin-bottom: 0;
          a {
            width: 100%;
            display: block;
            dl {
              .photo {
                figure {
                  padding-top: 63.29113924050633%;
                }
              }
              .text {
                margin-top: 10px;
                p + p { margin-top: 6px; }
              }
            }
          }
          .slick-arrow {
            top: 37.73584905660377%;
          }
          .slick-dots {
            position: relative;
            bottom: auto;
            background-color: $color_gray1;
            border-radius: 7px;
            display: flex;
            margin-top: 15px;
            li {
              height: 10px;
              flex: 1;
              margin: 0;
              button {
                width: 100%;
                height: 100%;
                padding: 0;
                background-color: #88B28E;
                border-radius: 7px;
                opacity: 0;
                &:before { display: none; }
              }
              &.slick-active button {
                opacity: 1;
              }
            }
          }
        }
      }
      #ContBox03 {
        padding: 40px 0 82px;
        .contSubBox {
          flex-direction: column;
          h3 {
            width: 100%;
            text-align: center;
            margin-bottom: 37px;
          }
        }
      }
    }
  }

  /*
  会社案内
  -------------------------------------*/
  #PageCompany.pageIndex {
    #MainImg {
      #MainImgInner { background-image: url(../img/contents/company/mainimg_sp.jpg); }
    }
    #Main {
      padding-top: 50px;
      padding-bottom: 115px;
      #ContBox01 {
        .boxLink {
          padding: 0 15px;
          margin-bottom: 41px;
          ul {
            display: flex;
            flex-wrap: wrap;
            li {
              width: calc( (100% - 20px) / 2);
              margin-right: 20px;
              margin-bottom: 14px;
              &:nth-of-type(even) {
                margin-right: 0;
              }
              a {
                width: 100%;
                height: 100%;
                border: 1px solid $txt_green;
                padding: 19px 17px;
                align-items: center;
                span {
                  color: $txt_green;
                  margin-right: 7px;
                  transform: translateY(1.5px);
                }
              }
            }
          }
        }
        .contSubBox {
          margin-bottom: 50px;
          .subBox {
            padding: 0;
          }
        }
        .contSubBox02 {
          .box01 {
            margin: 20px 0;
            padding: 20px;
          }
        }
        .contSubBox05 {
          .subBox {
            padding-bottom: 40px;
          }
        }
      }
    }
  }

  /*
  事業内容
  -------------------------------------*/
  #PageBusiness {
    #MainImg {
      #MainImgInner { background-image: url(../img/contents/business/mainimg_sp.jpg); }
    }
    #Main {
      padding-top: 50px;
      padding-bottom: 93px;
    }
  }
  #PageBusiness.pageIndex,
  #PageBusiness.pageCategory {
    #Main {
      #ContBox01 {
        h3 {
          margin-bottom: 0;
        }
        .innerBasic {
          > .box { margin-bottom: 50px; }
        }
        .box {
          flex-direction: column;
          .image {
            width: 100%;
            margin: 0 0 15px 0;
          }
        }
        .contSubBox {
          h4 { margin-bottom: 20px; }
          .subBox { padding: 0; }
        }
        .contSubBox01 {
          margin-bottom: 50px;
          dl {
            flex-direction: column;
            & + dl { margin-top: 15px; }
            dt {
              width: 100%;
              padding: 8px 20px;
            }
            dd {
              padding: 15px 0 0;
              ul li {
                line-height: 20px;
                &:before { top: 6px; }
                & + li { margin-top: 10px; }
              }
            }
          }
        }
        .contSubBox02 {
          margin-bottom: 30px;
          ul {
            li {
              width: calc( (100% - 20px ) / 2 );
              margin-right: 20px;
              margin-bottom: 20px;
              &:nth-of-type(even) { margin-right: 0; }
              p {
                font-size: 14px;
                line-height: 20px;
                margin-top: 7px;
              }
            }
          }
        }
        .contSubBox03 {
          .box {
            .image {
              width: 100%;
              position: relative;
              .imageSlide {
                width: calc(100% - 40px);
                margin: 0 auto;
              }
              .imageThumb {
                li { pointer-events: none; }
              }
              .arrows {
                position: absolute;
                top: 30%;
                left: 0;
                width: 100%;
                .arrow {
                  width: 50px;
                  height: 50px;
                  background-repeat: no-repeat;
                  background-position: left top;
                  background-size: 100% auto;
                  position: absolute;
                  top: 0;
                  &.hide { display: none; }
                }
                .prev {
                  left: 0;
                  background-image: url(../img/contents/btn_01_01.png);
                }
                .next {
                  right: 0;
                  background-image: url(../img/contents/btn_01_02.png);
                }
              }
            }
          }
        }
      }
    }
  }

  /*
  各事業建設
  -------------------------------------*/
  #PageBusiness.pageEntry {
    #Main {
      #ContBox01 {
        h3 { margin-bottom: 20px; }
        .subBox {
          padding: 0;
          .box {
            flex-direction: column;
            margin-bottom: 20px;
            .image { width: 100%; margin-bottom: 20px; position: relative;
              .imageSlide {
                width: calc(100% - 40px);
                margin: 0 auto;
              }
              .arrows {
                position: absolute;
                top: 30%;
                width: 100%;
                display: flex;
                justify-content: space-between;
                .arrow {
                  width: 50px;
                  height: 50px;
                  background-repeat: no-repeat;
                  background-position: left top;
                  background-size: 100% auto;
                  position: absolute;
                  top: 0;
                  &.hide { display: none; }
                }
                .prev {
                  left: 0;
                  background-image: url(../img/contents/btn_01_01.png);
                }
                .next {
                  right: 0;
                  background-image: url(../img/contents/btn_01_02.png);
                }
              }
            }
            .text {
              dd {
                padding: 20px 0 0;
              }
            }
          }
          table {
            th, td {
              padding: 12px 13px 13px;
            }
          }
        }
      }
    }
  }

  /*
  採用情報
  -------------------------------------*/
  #PageRecruit.pageIndex {
    #MainImg {
      #MainImgInner { background-image: url(../img/contents/recruit/mainimg_sp.jpg); }
    }
    #Main {
      padding-top: 50px;
      padding-bottom: 70px;
      .boxLink {
        margin-bottom: 50px;
        ul li {
          width: calc( (100% -  20px) / 2 );
          max-width: 165px;
          margin-right: 20px;
          &:nth-of-type(even) { margin-right: 0; }
        }
      }
      .contBox {
        margin-top: 100px;
        h3 {
          margin-bottom: 30px;
        }
        .subBox {
          padding: 0;
        }
      }
    }
  }

  /*
  お問合せ・見積依頼
  -------------------------------------*/
  #PageContact {
    #MainImg {
      #MainImgInner { background-image: url(../img/contents/contact/mainimg_sp.jpg); }
    }
  }
  #PageContact.pageIndex {
    #Main {
      padding-top: 50px;
      padding-bottom: 0;
      #ContBox01 {
        h3 {
          margin-bottom: 30px;
        }
        .subBox {
          padding: 0;
          > p { margin-bottom: 35px; }
        }
        .form {
          width: calc(100% + 30px);
          margin-left: -15px;
          padding: 34px 15px 95px;
          .privacy {
            margin: 30px 0 25px;
            height: 330px;
            padding: 20px 20px 15px 16px;
          }
          .agree {
            .image { margin-left: auto; }
          }
          button {
            margin-top: 30px;
          }
        }
      }
    }
  }

  /*
  お問合せ・見積依頼（送信内容確認）
  -------------------------------------*/
  #PageContact.pageConfirm {
    #Main {
      padding-top: 44px;
      padding-bottom: 0;
      #ContBox01 {
        .subBox {
          padding: 0;
          > p {
            width: 100%;
          }
        }
        h3 {
          margin-bottom: 28px;
        }
        .errBox {
          padding-bottom: 100px;
        }
        .form {
          width: calc(100% + 30px);
          padding: 52px 15px 77px;
          margin-left: -15px;
          button {
            margin-top: 50px;
          }
        }
      }
    }
  }

  /*
  お問合せ・見積依頼（送信完了）
  -------------------------------------*/
  #PageContact.pageThanks {
    #Main {
      padding-top: 44px;
      padding-bottom: 93px;
      #ContBox01 {
        h3 {
          margin-bottom: 28px;
        }
        .subBox {
          .box {
            max-width: 210px;
            margin: 45px auto;
            p {
              line-height: 20px;
            }
          }
        }
      }
    }
  }

  /*
  お知らせ
  -------------------------------------*/
  #PageTopics {
    #MainImg {
      #MainImgInner { background-image: url(../img/contents/topics/mainimg_sp.jpg); }
    }
    #Main {
      padding-top: 50px;
      padding-bottom: 75px;
      .cateLink {
        margin-bottom: 30px;
        ul { display: none; }
        select {
          width: 100%;
          line-height: 24px;
          background: url(../img/contents/arrow_03_01.png) no-repeat right 28px center;
          padding: 17px 50px 17px 23px;
          background-size: 13px auto;
          border: 1px solid $color_gray3;
        }
      }
    }
  }

  /*
  お知らせ（カテゴリ）
  -------------------------------------*/
  #PageTopics.pageCategory {
  }

  /*
  お知らせ（記事）
  -------------------------------------*/
  #PageTopics.pageEntry {
    #Main {
      padding-bottom: 116px;
      #ContBox01 {
        .contSubBox {
          padding: 30px;
        }
        .entryHead {
          ul {
            margin-bottom: 35px;
            justify-content: space-between;
          }
          h3 {
            font-size: 22px;
            line-height: 30px;
          }
        }
        .pageNavi {
          ul {
            max-width: 280px;
            padding-top: 57px;
            .prev, .next {
              top: 0;
              transform: translateY(0);
              a:hover {
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  /*
  個人情報保護方針
  -------------------------------------*/
  #PagePrivacy.pageIndex {
    #MainImg {
      #MainImgInner { background-image: url(../img/contents/company/mainimg_sp.jpg); }
    }
    #Main {
      padding-top: 44px;
      padding-bottom: 107px;
      #ContBox01 {
        .innerBasic {
          > p { margin-bottom: 37px; }
        }
        .contSubBox01 {
          margin-bottom: 60px;
          ol {
            li + li { margin-top: 20px; }
          }
          .subBox {
            margin: 47px 0 30px;
            align-items: center;
            .box {
              max-width: 264px;
              width: 73.33333333333333%;
              padding: 30px 14px;
              p {
                letter-spacing: 0;
                line-height: 25px;
                strong {
                  font-size: 16px;
                  margin-bottom: 12px;
                }
              }
            }
            figure {
              max-width: 90px;
              width: 25%;
              margin-left: 6px;
              img {
                width: 100%;
                max-width: 90px;
                height: auto;
              }
            }
          }
        }
        .contSubBox02 {
          .subBox {
            .box04 {
              h5 { margin-bottom: 25px; }
            }
          }
        }
      }
    }
  }

}


@media print, screen and (max-width: 400px) {
  #Page.pageIndex {
    #Main {
      #ContBox02 .contSubBox .box01 {
        .content ul li:nth-of-type(2n) { margin-right: 0; }
      }
    }
  }

}